import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18af512a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar__mobile_header" }
const _hoisted_2 = {
  key: 0,
  class: "level"
}
const _hoisted_3 = { class: "level-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_SidebarHeader = _resolveComponent("SidebarHeader")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_page_header = _resolveComponent("el-page-header")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createBlock(_component_el_container, {
    class: "main-layout",
    lang: _ctx.$i18n.locale
  }, {
    default: _withCtx(() => [
      (_ctx.hasSidebar)
        ? (_openBlock(), _createBlock(_component_el_aside, {
            key: 0,
            class: "sidebar"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "sidebar", {}, undefined, true)
            ]),
            _: 3
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_header, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_SidebarHeader, {
                  "sidebar-visible": _ctx.displaySettings,
                  "onUpdate:sidebarVisible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.displaySettings) = $event))
                }, null, 8, ["sidebar-visible"])
              ]),
              _createVNode(_component_el_scrollbar, null, {
                default: _withCtx(() => [
                  (!_ctx.hasSidebar)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("span", _hoisted_3, [
                          (!_ctx.hasSidebar)
                            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                key: 0,
                                icon: _ctx.getIconName(),
                                class: "logo"
                              }, null, 8, ["icon"]))
                            : _createCommentVNode("", true),
                          (_ctx.currentRouteTitle.length > 0)
                            ? (_openBlock(), _createBlock(_component_el_page_header, {
                                key: 1,
                                class: "level-item",
                                content: _ctx.currentRouteTitle,
                                title: _ctx.$t('general.back'),
                                onBack: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.go(-1)))
                              }, null, 8, ["content", "title"]))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("nav", {
                          class: _normalizeClass(["level-right", {
                'nav--white': _ctx.white,
              }])
                        }, [
                          (_ctx.isAdmin)
                            ? (_openBlock(), _createBlock(_component_router_link, {
                                key: 0,
                                to: "/user-list",
                                class: "level-item"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_font_awesome_icon, {
                                    icon: "users",
                                    class: "nav__item-icon"
                                  }),
                                  _createTextVNode(" " + _toDisplayString(_ctx.$t('moderator.molecule.navigation.userlist')), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_router_link, {
                            to: "/sessions",
                            class: "level-item"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_font_awesome_icon, {
                                icon: "home",
                                class: "nav__item-icon"
                              }),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t('moderator.molecule.navigation.sessions')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_router_link, {
                            to: "/profile",
                            class: "level-item"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_font_awesome_icon, {
                                icon: "user",
                                class: "nav__item-icon"
                              }),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t('moderator.molecule.navigation.profile')), 1)
                            ]),
                            _: 1
                          }),
                          (_ctx.hasSidebar)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 1,
                                class: "level-item sidebar__trigger",
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.displaySettings = true))
                              }, [
                                _createVNode(_component_font_awesome_icon, {
                                  icon: "cog",
                                  class: "nav__item-icon"
                                }),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t('moderator.molecule.navigation.settings')), 1)
                              ]))
                            : _createCommentVNode("", true)
                        ], 2)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _renderSlot(_ctx.$slots, "header", {}, undefined, true)
            ]),
            _: 3
          }),
          _createVNode(_component_el_main, {
            class: _normalizeClass(["main-layout__content", _ctx.contentClass])
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "content", {}, undefined, true)
            ]),
            _: 3
          }, 8, ["class"])
        ]),
        _: 3
      }),
      (_openBlock(), _createBlock(_component_el_drawer, {
        modelValue: _ctx.displaySettings,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.displaySettings) = $event)),
        direction: "ltr",
        size: "300px",
        key: _ctx.displaySettings,
        class: "sidebar"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "sidebar", {}, undefined, true)
        ]),
        _: 3
      }, 8, ["modelValue"]))
    ]),
    _: 3
  }, 8, ["lang"]))
}