import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-135f0aa9"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "card__image__icon"
}
const _hoisted_2 = {
  key: 3,
  class: "card__image__overlay"
}
const _hoisted_3 = { class: "card__title" }
const _hoisted_4 = ["lang"]
const _hoisted_5 = {
  key: 0,
  class: "idea-count"
}
const _hoisted_6 = { class: "actions" }
const _hoisted_7 = {
  key: 0,
  class: "state"
}
const _hoisted_8 = ["lang"]
const _hoisted_9 = {
  key: 1,
  class: "collapse"
}
const _hoisted_10 = {
  key: 0,
  class: "card__drag"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IdeaMediaViewer = _resolveComponent("IdeaMediaViewer")!
  const _component_markdown_render = _resolveComponent("markdown-render")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ToolTip = _resolveComponent("ToolTip")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_IdeaSettings = _resolveComponent("IdeaSettings")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, {
    id: _ctx.idea.id,
    ref: "ideaCard",
    shadow: "never",
    class: _normalizeClass(["idea-card", {
      landscape: !_ctx.portrait,
      card: !_ctx.portrait,
      card__selected: _ctx.isSelected,
      card__new: _ctx.isNew,
      card__handled: _ctx.isHandled,
      card__thumbs_up: _ctx.isThumbsUp,
      card__thumbs_down: _ctx.isThumbsDown,
      card__duplicate: _ctx.isDuplicate,
      draggable: _ctx.isDraggable,
      'idea-transform': _ctx.fadeIn,
      'fix-height': !!_ctx.fixHeight,
    }]),
    onClick: _ctx.changeSelection,
    "body-style": { padding: '0px' },
    style: _normalizeStyle({
      '--fix-card-height': _ctx.fixHeight,
      '--card-height': _ctx.ideaHeight,
      '--selection-color': _ctx.selectionColor,
      '--background-color': _ctx.backgroundColor,
    })
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["card__image", { 'image-height': !!_ctx.imageHeight }]),
        style: _normalizeStyle({ '--image-height': _ctx.imageHeight })
      }, [
        (_ctx.idea.image || _ctx.idea.link)
          ? (_openBlock(), _createBlock(_component_IdeaMediaViewer, {
              key: 0,
              idea: _ctx.idea,
              "allow-image-preview": _ctx.allowImagePreview,
              "image-preview-teleported": _ctx.imagePreviewTeleported,
              fit: "cover"
            }, null, 8, ["idea", "allow-image-preview", "image-preview-teleported"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
            ])),
        (_ctx.imageOverlayIsClickable)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: "card__image__overlay",
              onTouchstart: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.touchStart && _ctx.touchStart(...args))),
              onTouchend: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args))),
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
            }, [
              _renderSlot(_ctx.$slots, "image_overlay", {}, undefined, true)
            ], 32))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "image_overlay", {}, undefined, true)
            ]))
      ], 6),
      _createElementVNode("div", {
        class: "card__text",
        onTouchstart: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.touchStart && _ctx.touchStart(...args))),
        onTouchend: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args))),
        onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", {
            ref: "title",
            class: _normalizeClass(["line-break", { threeLineText: _ctx.cutLongTexts || _ctx.limitedTextLength }]),
            lang: _ctx.$i18n.locale
          }, [
            (_ctx.idea.count > 1)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.idea.count) + "x ", 1))
              : _createCommentVNode("", true),
            _createVNode(_component_markdown_render, {
              source: 
              _ctx.hasKeywords && (_ctx.showKeyword || !_ctx.idea.description)
                ? _ctx.idea.keywords
                : _ctx.idea.description
            
            }, null, 8, ["source"])
          ], 10, _hoisted_4),
          _createElementVNode("span", _hoisted_6, [
            _renderSlot(_ctx.$slots, "action", {}, undefined, true),
            (_ctx.showState && _ctx.stateIcon)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                  (_ctx.stateIcon)
                    ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                        key: 0,
                        icon: _ctx.stateIcon,
                        style: _normalizeStyle({ color: _ctx.stateColor })
                      }, null, 8, ["icon", "style"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.isEditable && (_ctx.canChangeState || _ctx.isSharable || _ctx.canBeChanged))
              ? (_openBlock(), _createBlock(_component_el_dropdown, {
                  key: 1,
                  class: "card__menu",
                  onCommand: _cache[8] || (_cache[8] = ($event: any) => (_ctx.menuItemSelected($event))),
                  trigger: "click",
                  "hide-on-click": !_ctx.preventClosing
                }, {
                  dropdown: _withCtx(() => [
                    _createVNode(_component_el_dropdown_menu, null, {
                      default: _withCtx(() => [
                        (_ctx.isSharable)
                          ? (_openBlock(), _createBlock(_component_el_dropdown_item, { key: 0 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ToolTip, {
                                  placement: 'right',
                                  text: 
                      _ctx.shareStateValue
                        ? _ctx.$t(
                            'moderator.organism.processTimeline.deactivateParticipant'
                          )
                        : _ctx.$t(
                            'moderator.organism.processTimeline.activateParticipant'
                          )
                    
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_switch, {
                                      modelValue: _ctx.shareStateValue,
                                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.shareStateValue) = $event)),
                                      onClick: _ctx.levelSharedChanged
                                    }, null, 8, ["modelValue", "onClick"])
                                  ]),
                                  _: 1
                                }, 8, ["text"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.canBeChanged)
                          ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                              key: 1,
                              command: "edit"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ToolTip, {
                                  placement: 'right',
                                  text: _ctx.$t('moderator.organism.settings.ideaSettings.edit')
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_font_awesome_icon, { icon: "pen" })
                                  ]),
                                  _: 1
                                }, 8, ["text"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.canBeChanged)
                          ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                              key: 2,
                              command: "delete"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ToolTip, {
                                  placement: 'right',
                                  text: 
                      _ctx.$t('moderator.organism.settings.ideaSettings.delete')
                    
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_font_awesome_icon, { icon: "trash" })
                                  ]),
                                  _: 1
                                }, 8, ["text"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.canChangeState)
                          ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                              key: 3,
                              command: "state",
                              onMousedown: _cache[7] || (_cache[7] = ($event: any) => (_ctx.preventClosing = true))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ToolTip, {
                                  placement: 'right',
                                  text: _ctx.$t('moderator.organism.settings.ideaSettings.state')
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_dropdown, {
                                      class: "card__menu",
                                      placement: "top-start",
                                      onCommand: _cache[6] || (_cache[6] = ($event: any) => (_ctx.menuItemSelected($event))),
                                      trigger: "click"
                                    }, {
                                      dropdown: _withCtx(() => [
                                        _createVNode(_component_el_dropdown_menu, {
                                          onMousedown: _cache[5] || (_cache[5] = ($event: any) => (_ctx.preventClosing = false))
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.IdeaStates, (ideaState) => {
                                              return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                                key: ideaState,
                                                command: ideaState
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.$t(`enum.ideaState.${ideaState}`)), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["command"]))
                                            }), 128))
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_font_awesome_icon, { icon: "star" })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["text"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _renderSlot(_ctx.$slots, "dropdown", {}, undefined, true)
                      ]),
                      _: 3
                    })
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      class: "el-dropdown-link",
                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.stopPropagation && _ctx.stopPropagation(...args)))
                    }, [
                      _createVNode(_component_ToolTip, {
                        text: _ctx.$t('moderator.organism.settings.ideaSettings.header')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_font_awesome_icon, { icon: "ellipsis-h" })
                        ]),
                        _: 1
                      }, 8, ["text"])
                    ])
                  ]),
                  _: 3
                }, 8, ["hide-on-click"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true),
        (_ctx.hasKeywords && _ctx.idea.description && _ctx.showKeyword)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              ref: "description",
              class: _normalizeClass(["card__content line-break", {
          threeLineText:
            _ctx.cutLongTexts ||
            (_ctx.limitedTextLength && !_ctx.ignoreLimitedDescriptionLength),
        }]),
              lang: _ctx.$i18n.locale
            }, [
              _createVNode(_component_markdown_render, {
                class: "markdown",
                source: _ctx.idea.description
              }, null, 8, ["source"])
            ], 10, _hoisted_8))
          : _createCommentVNode("", true),
        (_ctx.isLongText && !_ctx.cutLongTexts && !_ctx.ignoreLimitedDescriptionLength)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_font_awesome_icon, {
                icon: _ctx.limitedTextLength ? 'angle-down' : 'angle-up',
                onClick: _ctx.collapseChanged
              }, null, 8, ["icon", "onClick"])
            ]))
          : _createCommentVNode("", true)
      ], 32),
      (_ctx.showDragArea)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createVNode(_component_ToolTip, {
              placement: 'left',
              text: _ctx.$t('moderator.organism.settings.topicSettings.drag')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: "grip-vertical",
                  class: "card__drag__icon"
                })
              ]),
              _: 1
            }, 8, ["text"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isEditable)
        ? (_openBlock(), _createBlock(_component_IdeaSettings, {
            key: 1,
            "show-modal": _ctx.showSettings,
            "onUpdate:showModal": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.showSettings) = $event)),
            idea: _ctx.idea,
            authHeaderTyp: _ctx.authHeaderTyp,
            onUpdateData: _ctx.updateData
          }, null, 8, ["show-modal", "idea", "authHeaderTyp", "onUpdateData"]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["id", "onClick", "class", "style"]))
}