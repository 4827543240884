import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Bar = _resolveComponent("Bar")!

  return (_openBlock(), _createBlock(_component_Bar, {
    id: "chartRef",
    ref: "chartRef",
    data: _ctx.resultData,
    height: 100,
    options: {
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      scales: {
        x: {
          ticks: {
            color: _ctx.contrastColor,
          },
          grid: {
            display: false,
          },
        },
        y: {
          ticks: {
            color: _ctx.contrastColor,
            precision: 0,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: _ctx.$t('moderator.organism.statistics.participantActivity'),
        },
      },
    }
  }, null, 8, ["data", "options"]))
}