import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dd69618c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["lang"]
const _hoisted_2 = { class: "participant-container join full-height centered-horizontal flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_ValidationForm = _resolveComponent("ValidationForm")!

  return (_openBlock(), _createElementBlock("div", {
    class: "participant-background",
    lang: _ctx.$i18n.locale
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("main", null, [
        _createElementVNode("h1", {
          class: _normalizeClass(["heading heading--big heading--white", _ctx.textColorClass()])
        }, _toDisplayString(_ctx.$t('participant.view.join.header')), 3),
        _createElementVNode("p", {
          class: _normalizeClass(["join__text", _ctx.textColorClass()])
        }, _toDisplayString(_ctx.$t('participant.view.join.info')), 3),
        _createVNode(_component_ValidationForm, {
          "form-data": _ctx.formData,
          "submit-label-key": "participant.view.join.submit",
          onSubmitDataValid: _ctx.connectToSession
        }, {
          default: _withCtx(() => [
            (_ctx.browserKeyIsSet)
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 0,
                  prop: "browserKey"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _ctx.formData.browserKey,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.browserKey) = $event))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recentlyUsedKeys, (info) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: info.connectionKey,
                            label: info.title,
                            value: info.connectionKey
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"]),
                    _createElementVNode("p", {
                      class: _normalizeClass(["join__info", _ctx.textColorClass()])
                    }, _toDisplayString(_ctx.$t('participant.view.join.browserKeyInfo')), 3)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_el_form_item, {
              prop: "connectionKey",
              rules: _ctx.validateRules
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.formData.connectionKey,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.connectionKey) = $event)),
                  name: "connectionKey",
                  autocomplete: "on",
                  placeholder: _ctx.$t('participant.view.join.pinInfo')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["rules"])
          ]),
          _: 1
        }, 8, ["form-data", "onSubmitDataValid"])
      ])
    ])
  ], 8, _hoisted_1))
}