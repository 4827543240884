import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, renderSlot as _renderSlot, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "el-dialog__title"
}
const _hoisted_2 = {
  key: 1,
  class: "el-dialog__title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_MarkdownEditor = _resolveComponent("MarkdownEditor")!
  const _component_ImagePicker = _resolveComponent("ImagePicker")!
  const _component_FromSubmitItem = _resolveComponent("FromSubmitItem")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_ValidationForm = _resolveComponent("ValidationForm")!

  return (_openBlock(), _createBlock(_component_ValidationForm, {
    "form-data": _ctx.formData,
    "use-default-submit": false,
    onSubmitDataValid: _ctx.save,
    onReset: _ctx.reset,
    ref: "dataForm"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.showSettings,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showSettings) = $event)),
        width: "calc(var(--app-width) * 0.8)",
        "before-close": _ctx.handleClose
      }, {
        header: _withCtx(() => [
          (_ctx.title)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.title), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('moderator.organism.settings.ideaSettings.header')), 1))
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_FromSubmitItem, {
            "form-state-message": _ctx.formData.stateMessage,
            "submit-label-key": "moderator.organism.settings.ideaSettings.submit",
            disabled: _ctx.isSaving
          }, null, 8, ["form-state-message", "disabled"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('moderator.organism.settings.ideaSettings.keywords'),
            prop: "keywords",
            rules: [
          _ctx.defaultFormRules.ruleRequired,
          _ctx.defaultFormRules.ruleToLong(_ctx.MAX_KEYWORDS_LENGTH),
        ]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.formData.keywords,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.keywords) = $event)),
                placeholder: 
            _ctx.$t('moderator.organism.settings.ideaSettings.keywordsExample')
          
              }, null, 8, ["modelValue", "placeholder"]),
              _createElementVNode("span", {
                class: _normalizeClass(["info", {
            error: _ctx.MAX_KEYWORDS_LENGTH < _ctx.formData.keywords.length,
          }])
              }, _toDisplayString(_ctx.$t('module.brainstorming.default.participant.remainingCharacters')) + ": " + _toDisplayString(_ctx.MAX_KEYWORDS_LENGTH - _ctx.formData.keywords.length), 3)
            ]),
            _: 1
          }, 8, ["label", "rules"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('moderator.organism.settings.ideaSettings.description'),
            prop: "description",
            rules: [_ctx.defaultFormRules.ruleToLong(_ctx.MAX_DESCRIPTION_LENGTH)]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MarkdownEditor, {
                modelValue: _ctx.formData.description,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.description) = $event)),
                placeholder: 
            _ctx.$t('moderator.organism.settings.ideaSettings.descriptionExample')
          
              }, null, 8, ["modelValue", "placeholder"]),
              _createElementVNode("span", {
                class: _normalizeClass(["info", {
            error: _ctx.MAX_DESCRIPTION_LENGTH < _ctx.formData.description.length,
          }])
              }, _toDisplayString(_ctx.$t('module.brainstorming.default.participant.remainingCharacters')) + ": " + _toDisplayString(_ctx.MAX_DESCRIPTION_LENGTH - _ctx.formData.description.length), 3)
            ]),
            _: 1
          }, 8, ["label", "rules"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('moderator.organism.settings.ideaSettings.image'),
            prop: "image"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ImagePicker, {
                link: _ctx.formData.link,
                "onUpdate:link": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.link) = $event)),
                image: _ctx.formData.image,
                "onUpdate:image": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.image) = $event))
              }, null, 8, ["link", "image"])
            ]),
            _: 1
          }, 8, ["label"]),
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["modelValue", "before-close"])
    ]),
    _: 3
  }, 8, ["form-data", "onSubmitDataValid", "onReset"]))
}