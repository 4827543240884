import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ToolTip = _resolveComponent("ToolTip")!
  const _component_ModuleCount = _resolveComponent("ModuleCount")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_TaskTimeline = _resolveComponent("TaskTimeline")!
  const _component_TopicCard = _resolveComponent("TopicCard")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_AddItem = _resolveComponent("AddItem")!
  const _component_TutorialStep = _resolveComponent("TutorialStep")!
  const _component_TopicSettings = _resolveComponent("TopicSettings")!
  const _component_SessionSettings = _resolveComponent("SessionSettings")!
  const _component_FacilitatorSettings = _resolveComponent("FacilitatorSettings")!
  const _component_ParticipantSettings = _resolveComponent("ParticipantSettings")!
  const _component_ModeratorNavigationLayout = _resolveComponent("ModeratorNavigationLayout")!

  return (_ctx.session)
    ? (_openBlock(), _createBlock(_component_ModeratorNavigationLayout, { key: 0 }, {
        sidebar: _withCtx(() => [
          _createVNode(_component_Sidebar, {
            title: _ctx.session.title,
            description: _ctx.session.description,
            canModify: _ctx.isModerator,
            session: _ctx.session,
            onOpenSettings: _ctx.editSession,
            onDelete: _ctx.deleteSession
          }, {
            management: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatDate(_ctx.session.creationDate)), 1)
            ]),
            settings: _withCtx(() => [
              _createElementVNode("span", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.download && _ctx.download(...args)))
              }, [
                _createVNode(_component_ToolTip, {
                  effect: 'light',
                  text: _ctx.$t('moderator.organism.settings.sidebarSettings.download')
                }, {
                  default: _withCtx(() => [
                    (!_ctx.isDownloading)
                      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                          key: 0,
                          class: "awesome-icon",
                          icon: "download"
                        }))
                      : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                          key: 1,
                          class: "awesome-icon fa-spin",
                          icon: "spinner"
                        }))
                  ]),
                  _: 1
                }, 8, ["text"])
              ]),
              (!_ctx.isModerator)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.disconnect && _ctx.disconnect(...args)))
                  }, [
                    _createVNode(_component_font_awesome_icon, {
                      class: "awesome-icon",
                      icon: "user-slash"
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isModerator)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showRoles = true))
                  }, [
                    _createVNode(_component_ToolTip, {
                      effect: 'light',
                      text: 
                _ctx.$t('moderator.organism.settings.facilitatorSettings.header')
              
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, {
                          class: "awesome-icon",
                          icon: "user-group"
                        })
                      ]),
                      _: 1
                    }, 8, ["text"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isModerator)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 2,
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showParticipants = true))
                  }, [
                    _createVNode(_component_ToolTip, {
                      effect: 'light',
                      text: 
                _ctx.$t('moderator.organism.settings.participantSettings.header')
              
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, {
                          class: "awesome-icon",
                          icon: "users"
                        })
                      ]),
                      _: 1
                    }, 8, ["text"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            headerContent: _withCtx(() => [
              _createElementVNode("span", {
                class: _normalizeClass({ expired: _ctx.isExpired })
              }, _toDisplayString(_ctx.$t('moderator.organism.settings.sessionSettings.expirationDate')) + ": " + _toDisplayString(_ctx.formatDate(_ctx.session.expirationDate)), 3),
              _createVNode(_component_ModuleCount, { session: _ctx.session }, null, 8, ["session"])
            ]),
            _: 1
          }, 8, ["title", "description", "canModify", "session", "onOpenSettings", "onDelete"])
        ]),
        content: _withCtx(() => [
          (_ctx.topics)
            ? (_openBlock(), _createBlock(_component_draggable, {
                key: 0,
                modelValue: _ctx.topics,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.topics) = $event)),
                "item-key": "order",
                handle: ".card__drag",
                onEnd: _ctx.dragDone
              }, {
                item: _withCtx(({ element }) => [
                  (_openBlock(), _createElementBlock("div", {
                    class: "detail__module",
                    key: element.order
                  }, [
                    _createVNode(_component_TopicCard, {
                      sessionId: _ctx.sessionId,
                      topic: element,
                      canModify: _ctx.isModerator,
                      onTopicDeleted: _ctx.deleteTopic
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_component_TaskTimeline, {
                          "topic-id": element.id,
                          "session-id": _ctx.sessionId,
                          "is-linked-to-task": false,
                          key: _ctx.publicScreenTopic
                        }, null, 8, ["topic-id", "session-id"]))
                      ]),
                      _: 2
                    }, 1032, ["sessionId", "topic", "canModify", "onTopicDeleted"])
                  ]))
                ]),
                _: 1
              }, 8, ["modelValue", "onEnd"]))
            : _createCommentVNode("", true),
          (_ctx.isModerator)
            ? (_openBlock(), _createBlock(_component_TutorialStep, {
                key: 1,
                type: "sessionDetails",
                step: "addTopic",
                order: 5,
                width: 450
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AddItem, {
                    text: _ctx.$t('moderator.view.sessionDetails.addTopic'),
                    onAddNew: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showTopicSettings = true))
                  }, null, 8, ["text"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_TopicSettings, {
            "show-modal": _ctx.showTopicSettings,
            "onUpdate:showModal": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showTopicSettings) = $event)),
            "session-id": _ctx.sessionId,
            "topic-id": _ctx.editTopicId,
            onTopicUpdated: _ctx.reloadTopics
          }, null, 8, ["show-modal", "session-id", "topic-id", "onTopicUpdated"]),
          _createVNode(_component_SessionSettings, {
            "show-modal": _ctx.showSessionSettings,
            "onUpdate:showModal": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showSessionSettings) = $event)),
            "session-id": _ctx.sessionId,
            onSessionUpdated: _ctx.reloadSession
          }, null, 8, ["show-modal", "session-id", "onSessionUpdated"]),
          (_ctx.isModerator)
            ? (_openBlock(), _createBlock(_component_FacilitatorSettings, {
                key: 2,
                showModal: _ctx.showRoles,
                "onUpdate:showModal": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showRoles) = $event)),
                sessionId: _ctx.sessionId
              }, null, 8, ["showModal", "sessionId"]))
            : _createCommentVNode("", true),
          (_ctx.isModerator)
            ? (_openBlock(), _createBlock(_component_ParticipantSettings, {
                key: 3,
                "show-modal": _ctx.showParticipants,
                "onUpdate:showModal": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.showParticipants) = $event)),
                "session-id": _ctx.sessionId
              }, null, 8, ["show-modal", "session-id"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}