import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24f47c9b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top-container" }
const _hoisted_2 = { class: "el-card__date" }
const _hoisted_3 = { class: "heading heading--regular threeLineText line-break" }
const _hoisted_4 = { class: "el-card__description threeLineText line-break" }
const _hoisted_5 = { class: "el-card__content" }
const _hoisted_6 = {
  key: 0,
  class: "flex-grow subject"
}
const _hoisted_7 = { class: "el-card__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ToolTip = _resolveComponent("ToolTip")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_ModuleCount = _resolveComponent("ModuleCount")!
  const _component_SessionCode = _resolveComponent("SessionCode")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_footer = _resolveComponent("el-footer")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_SessionSettings = _resolveComponent("SessionSettings")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_card, { shadow: "never" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_container, { style: {"height":"100%"} }, {
          default: _withCtx(() => [
            _createVNode(_component_el_header, null, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.formatDate(_ctx.session.creationDate)), 1),
                    (_ctx.isModerator)
                      ? (_openBlock(), _createBlock(_component_el_dropdown, {
                          key: 0,
                          class: "card__menu",
                          onCommand: _ctx.menuItemSelected,
                          trigger: "click"
                        }, {
                          dropdown: _withCtx(() => [
                            _createVNode(_component_el_dropdown_item, { command: "edit" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('moderator.organism.cards.sessionCard.edit')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_dropdown_item, { command: "clone" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('moderator.organism.cards.sessionCard.clone')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_dropdown_item, { command: "delete" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('moderator.organism.cards.sessionCard.delete')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("span", {
                              class: "el-dropdown-link",
                              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.stopPropagation && _ctx.stopPropagation(...args)))
                            }, [
                              _createVNode(_component_ToolTip, {
                                text: 
                    _ctx.$t('moderator.organism.settings.sessionSettings.settings')
                  
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_font_awesome_icon, { icon: "ellipsis-h" })
                                ]),
                                _: 1
                              }, 8, ["text"])
                            ])
                          ]),
                          _: 1
                        }, 8, ["onCommand"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.session.title), 1),
                  _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.session.description), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_main),
            _createVNode(_component_el_footer, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  (_ctx.hasSubject)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(this.session.subject), 1))
                    : _createCommentVNode("", true)
                ]),
                _createVNode(_component_ModuleCount, { session: _ctx.session }, null, 8, ["session"]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_SessionCode, {
                    code: _ctx.session.connectionKey,
                    "button-type": "primary"
                  }, null, 8, ["code"]),
                  _createVNode(_component_router_link, {
                    to: `/session/${_ctx.session.id}`,
                    class: "flex-grow"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, {
                        class: "fullwidth",
                        type: "info"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('moderator.organism.cards.sessionCard.select')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["to"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_SessionSettings, {
      "show-modal": _ctx.showSettings,
      "onUpdate:showModal": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showSettings) = $event)),
      "session-id": _ctx.sessionEditId,
      onSessionUpdated: _ctx.onSessionUpdated,
      "onUpdate:subject": _ctx.onSubjectUpdated
    }, null, 8, ["show-modal", "session-id", "onSessionUpdated", "onUpdate:subject"])
  ], 64))
}