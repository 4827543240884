import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dacc3f8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["lang"]
const _hoisted_2 = { class: "profile__email" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_ValidationForm = _resolveComponent("ValidationForm")!

  return (_openBlock(), _createElementBlock("div", {
    class: "confirm__content full-height-header",
    lang: _ctx.$i18n.locale
  }, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t('moderator.view.forgetPassword.header')), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('moderator.view.forgetPassword.info')) + " " + _toDisplayString(_ctx.email), 1),
    _createVNode(_component_ValidationForm, {
      "form-data": _ctx.formData,
      "submit-label-key": "moderator.view.forgetPassword.submit",
      onSubmitDataValid: _ctx.save,
      ref: "dataForm"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('moderator.view.forgetPassword.newPassword'),
          prop: "newPassword",
          rules: [
          _ctx.defaultFormRules.ruleRequired,
          _ctx.defaultFormRules.rulePassword,
          _ctx.defaultFormRules.ruleToShort(8),
          _ctx.defaultFormRules.ruleToLong(255),
          _ctx.defaultFormRules.ruleTrigger(_ctx.dataForm, 'passwordRepeat'),
        ]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              type: "password",
              placeholder: _ctx.$t('moderator.view.forgetPassword.newPasswordInfo'),
              modelValue: _ctx.formData.newPassword,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.newPassword) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "rules"]),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('moderator.view.forgetPassword.passwordConform'),
          prop: "passwordRepeat",
          rules: [
          _ctx.defaultFormRules.ruleRequired,
          _ctx.defaultFormRules.rulePassword,
          _ctx.defaultFormRules.ruleToShort(8),
          _ctx.defaultFormRules.ruleToLong(255),
          _ctx.defaultFormRules.ruleMatch(_ctx.formData.newPassword, 'passwordNotMatch'),
        ]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              type: "password",
              placeholder: _ctx.$t('moderator.view.forgetPassword.passwordConformInfo'),
              modelValue: _ctx.formData.passwordRepeat,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.passwordRepeat) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "rules"])
      ]),
      _: 1
    }, 8, ["form-data", "onSubmitDataValid"])
  ], 8, _hoisted_1))
}