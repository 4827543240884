import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12988b8e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "media" }
const _hoisted_2 = {
  key: 0,
  class: "media-left"
}
const _hoisted_3 = {
  key: 1,
  class: "media-content"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 2,
  class: "media-right"
}
const _hoisted_6 = { class: "task-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!
  const _component_markdown_render = _resolveComponent("markdown-render")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'module-info--centered': _ctx.isParticipant }, "module-info"]),
    style: _normalizeStyle({ '--module-color': _ctx.getColor() })
  }, [
    _createElementVNode("span", _hoisted_1, [
      (!!_ctx.$slots.moduleInfoLeft)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
            _renderSlot(_ctx.$slots, "moduleInfoLeft", {}, undefined, true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.showType)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            (_ctx.taskType)
              ? (_openBlock(), _createBlock(_component_el_breadcrumb, {
                  key: 0,
                  separator: " | ",
                  class: "module-info__type oneLineText"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.moduleInfo, (module) => {
                      return (_openBlock(), _createBlock(_component_el_breadcrumb_item, { key: module }, {
                        default: _withCtx(() => [
                          module
                            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t(`module.${_ctx.taskType}.${module}.description.title`)), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (!!_ctx.$slots.moduleInfoRight)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
            _renderSlot(_ctx.$slots, "moduleInfoRight", {}, undefined, true)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.description && _ctx.collapseDescription)
      ? (_openBlock(), _createBlock(_component_el_collapse, {
          key: 0,
          class: _normalizeClass(["module-info__description", { twoLineText: _ctx.shortenDescription }])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_collapse_item, null, {
              title: _withCtx(() => [
                _createElementVNode("h3", {
                  class: _normalizeClass([{
              'heading--regular': _ctx.isParticipant,
              twoLineText: _ctx.shortenDescription,
            }, "module-info__title"])
                }, _toDisplayString(_ctx.title), 3)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_markdown_render, { source: _ctx.description }, null, 8, ["source"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]))
      : (_openBlock(), _createElementBlock("h3", {
          key: 1,
          class: _normalizeClass([{
        'heading--regular': _ctx.isParticipant,
        twoLineText: _ctx.shortenDescription,
      }, "module-info__title"])
        }, _toDisplayString(_ctx.title), 3)),
    (_ctx.description && !_ctx.collapseDescription)
      ? (_openBlock(), _createElementBlock("p", {
          key: 2,
          class: _normalizeClass(["module-info__description", { twoLineText: _ctx.shortenDescription }])
        }, [
          _createVNode(_component_markdown_render, { source: _ctx.description }, null, 8, ["source"])
        ], 2))
      : _createCommentVNode("", true)
  ], 6))
}