import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_markdown = _resolveComponent("vue-markdown")!

  return (_openBlock(), _createBlock(_component_vue_markdown, {
    class: "markdown",
    source: _ctx.source,
    options: { html: true }
  }, null, 8, ["source"]))
}