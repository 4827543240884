import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MdEditor = _resolveComponent("MdEditor")!

  return (_openBlock(), _createBlock(_component_MdEditor, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    language: "en-US",
    noUploadImg: "",
    class: "markdown",
    inputBoxWitdh: "70%",
    placeholder: _ctx.placeholder,
    toolbars: [
      'bold',
      'underline',
      'italic',
      'strikeThrough',
      '-',
      'title',
      'sub',
      'sup',
      'unorderedList',
      'orderedList',
      '-',
      'link',
      'image',
      'table',
      '-',
      'revoke',
      'next',
    ]
  }, null, 8, ["modelValue", "placeholder"]))
}