import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a19cb2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["lang"]
const _hoisted_2 = { class: "media-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("header", {
    class: "media",
    lang: _ctx.$i18n.locale
  }, [
    _createVNode(_component_font_awesome_icon, {
      icon: _ctx.getIconName(),
      class: "media-left logo"
    }, null, 8, ["icon"]),
    _createElementVNode("span", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 8, _hoisted_1))
}