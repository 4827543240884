import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c1e1140"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "content",
  class: "confirm__content full-height-header"
}
const _hoisted_2 = { class: "level" }
const _hoisted_3 = { class: "level-left" }
const _hoisted_4 = { class: "level-item" }
const _hoisted_5 = { class: "level-right" }
const _hoisted_6 = { class: "level-item" }
const _hoisted_7 = { class: "level-item" }
const _hoisted_8 = { class: "level-item" }
const _hoisted_9 = { class: "level-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_ModeratorNavigationLayout = _resolveComponent("ModeratorNavigationLayout")!

  return (_openBlock(), _createBlock(_component_ModeratorNavigationLayout, {
    currentRouteTitle: _ctx.$t('moderator.view.userList.header')
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('moderator.view.userList.header')), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_font_awesome_icon, {
                class: "icon",
                icon: "users"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.userList.length), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_font_awesome_icon, {
                class: "icon",
                icon: "check",
                style: {"color":"var(--color-green)"}
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.userList.filter((item) => item.confirmed).length), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_font_awesome_icon, {
                class: "icon",
                icon: "xmark",
                style: {"color":"var(--color-red)"}
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.userList.filter((item) => !item.confirmed).length), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_font_awesome_icon, {
                class: "icon",
                icon: "file"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.userList.reduce((a, b) => a + b.ownSessions, 0)), 1)
            ])
          ])
        ]),
        (_ctx.userList && _ctx.userList.length > 0)
          ? (_openBlock(), _createBlock(_component_el_table, {
              key: 0,
              data: _ctx.userList,
              style: {"width":"100%"},
              "max-height": _ctx.maxHeight - 50
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  prop: "username",
                  label: _ctx.$t('moderator.view.userList.username'),
                  sortable: ""
                }, null, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "creationDate",
                  label: _ctx.$t('moderator.view.userList.creationDate'),
                  sortable: ""
                }, null, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "confirmed",
                  label: _ctx.$t('moderator.view.userList.confirmed'),
                  sortable: ""
                }, {
                  default: _withCtx((scope) => [
                    (scope.row.confirmed)
                      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                          key: 0,
                          class: "icon",
                          icon: "check",
                          style: {"color":"var(--color-green)"}
                        }))
                      : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                          key: 1,
                          class: "icon",
                          icon: "xmark",
                          style: {"color":"var(--color-red)"}
                        }))
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "ownSessions",
                  label: _ctx.$t('moderator.view.userList.ownSessions'),
                  sortable: ""
                }, null, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "sharedSessions",
                  label: _ctx.$t('moderator.view.userList.sharedSessions'),
                  sortable: ""
                }, null, 8, ["label"]),
                _createVNode(_component_el_table_column, { width: "170" }, {
                  default: _withCtx((scope) => [
                    (!scope.row.confirmed)
                      ? (_openBlock(), _createBlock(_component_el_button, {
                          key: 0,
                          type: "primary",
                          onClick: ($event: any) => (_ctx.confirmUser(scope.$index))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('moderator.view.userList.confirm')), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data", "max-height"]))
          : _createCommentVNode("", true)
      ], 512)
    ]),
    _: 1
  }, 8, ["currentRouteTitle"]))
}