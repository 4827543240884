import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-120c287a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "next" }
const _hoisted_2 = { class: "prev" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ display: _ctx.active ? 'block' : 'none' }),
    class: "infoArea"
  }, [
    _renderSlot(_ctx.$slots, "prefix", {}, undefined, true),
    (_ctx.gameHeight)
      ? (_openBlock(), _createBlock(_component_el_carousel, {
          key: 0,
          ref: "carousel",
          autoplay: false,
          arrow: "never",
          height: `${_ctx.gameHeight}px`,
          trigger: "click",
          loop: false,
          onChange: _ctx.carouselChanged
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.openModuleInfoEntryDataList, (entry) => {
              return (_openBlock(), _createBlock(_component_el_carousel_item, {
                key: entry,
                class: "info-text"
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default", { key: entry }, undefined, true)
                ]),
                _: 2
              }, 1024))
            }), 128)),
            _renderSlot(_ctx.$slots, "content", {}, undefined, true)
          ]),
          _: 3
        }, 8, ["height", "onChange"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_button, {
        type: "primary",
        onClick: _ctx.next
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('participant.molecules.moduleInfo.next')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.activeTabIndex - 1 >= 0)
        ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            type: "primary",
            onClick: _ctx.prev
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('participant.molecules.moduleInfo.prev')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ])
  ], 4))
}