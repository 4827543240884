import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d719e1d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["anchor", "scale", "x"]
const _hoisted_2 = {
  key: 2,
  class: "navigation-overlay overlay-right"
}
const _hoisted_3 = {
  key: 3,
  class: "navigation-overlay overlay-left"
}
const _hoisted_4 = {
  key: 4,
  class: "navigation-overlay overlay-up"
}
const _hoisted_5 = {
  key: 5,
  class: "navigation-overlay overlay-down"
}
const _hoisted_6 = {
  key: 6,
  class: "frameInfo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sprite = _resolveComponent("sprite")!
  const _component_Graphics = _resolveComponent("Graphics")!
  const _component_Application = _resolveComponent("Application")!
  const _component_container = _resolveComponent("container")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    ref: "gameContainer",
    id: "gameContainer",
    class: "gameContainer",
    style: _normalizeStyle({
      '--game-width': `${_ctx.gameWidth}px`,
      '--game-height': `${_ctx.gameHeight}px`,
    }),
    onMouseup: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.endPan && _ctx.endPan(...args))),
    onMouseout: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.endPan && _ctx.endPan(...args))),
    onTouchend: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.endPan && _ctx.endPan(...args)))
  }, [
    (_ctx.ready && _ctx.backgroundMovement === _ctx.BackgroundMovement.Map)
      ? (_openBlock(), _createBlock(_component_Application, {
          key: 0,
          width: _ctx.gameWidth,
          height: _ctx.mapHeight,
          backgroundColor: _ctx.backgroundColor,
          backgroundAlpha: _ctx.backgroundAlpha
        }, {
          default: _withCtx(() => [
            (_ctx.backgroundSprite && _ctx.backgroundSprite.valid)
              ? (_openBlock(), _createBlock(_component_sprite, {
                  key: 0,
                  texture: _ctx.backgroundSprite,
                  anchor: 0,
                  alpha: 0.5,
                  width: _ctx.gameWidth,
                  height: _ctx.mapHeight,
                  x: 0,
                  y: 0
                }, null, 8, ["texture", "width", "height"]))
              : _createCommentVNode("", true),
            (_ctx.backgroundPositionOffset && _ctx.backgroundPositionOffset.length === 2)
              ? (_openBlock(), _createBlock(_component_Graphics, {
                  key: 1,
                  x: _ctx.gameObjectOffsetRelativeToBackground[0] * _ctx.mapScale,
                  onRender: _ctx.drawDisplayRegion,
                  onPointermove: _ctx.moveDisplayArea
                }, null, 8, ["x", "onRender", "onPointermove"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["width", "height", "backgroundColor", "backgroundAlpha"]))
      : _createCommentVNode("", true),
    (_ctx.ready)
      ? (_openBlock(), _createBlock(_component_Application, {
          key: 1,
          ref: "pixi",
          id: "pixiContainer",
          width: _ctx.gameWidth,
          height: _ctx.gameDisplayHeight,
          backgroundColor: _ctx.backgroundColor,
          backgroundAlpha: _ctx.backgroundAlpha,
          transparent: _ctx.transparent,
          onPointerdown: _ctx.gameContainerClicked,
          onPointerup: _ctx.gameContainerReleased
        }, {
          default: _withCtx(() => [
            (_ctx.$slots.preRender && _ctx.backgroundSprite)
              ? (_openBlock(), _createBlock(_component_container, {
                  key: 0,
                  gameContainer: this,
                  onRender: _ctx.preRenderData
                }, {
                  default: _withCtx(() => [
                    (_ctx.backgroundSprite && _ctx.backgroundSprite.valid)
                      ? (_openBlock(), _createBlock(_component_sprite, {
                          key: 0,
                          texture: _ctx.backgroundSprite,
                          anchor: 0.5,
                          width: _ctx.backgroundTextureSize[0],
                          height: _ctx.backgroundTextureSize[1],
                          x: _ctx.backgroundPositionOffset[0],
                          y: _ctx.backgroundPositionOffset[1]
                        }, null, 8, ["texture", "width", "height", "x", "y"]))
                      : _createCommentVNode("", true),
                    _renderSlot(_ctx.$slots, "preRender", {}, undefined, true)
                  ]),
                  _: 3
                }, 8, ["onRender"]))
              : _createCommentVNode("", true),
            _createVNode(_component_container, {
              filters: _ctx.pixiFilterList,
              gameContainer: this
            }, {
              default: _withCtx(() => [
                (
            _ctx.backgroundTexturePositionSprite &&
            _ctx.backgroundTexturePositionSprite.valid &&
            (!_ctx.$slots.preRender || _ctx.preRendered)
          )
                  ? (_openBlock(), _createBlock(_component_sprite, {
                      key: 0,
                      texture: _ctx.backgroundTexturePositionSprite,
                      anchor: 0.5,
                      width: _ctx.backgroundTexturePositionSize[0],
                      height: _ctx.backgroundTexturePositionSize[1],
                      x: _ctx.backgroundTexturePosition[0],
                      y: _ctx.backgroundTexturePosition[1],
                      filters: _ctx.pixiFilterListBackground
                    }, null, 8, ["texture", "width", "height", "x", "y", "filters"]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.regionBodyList, (region) => {
                  return (_openBlock(), _createBlock(_component_container, {
                    key: region.body.id,
                    x: region.body.position.x,
                    y: region.body.position.y
                  }, {
                    default: _withCtx(() => [
                      (region.body)
                        ? (_openBlock(), _createBlock(_component_Graphics, {
                            key: 0,
                            onRender: ($event: any) => (_ctx.drawRegion($event, region)),
                            x: 0,
                            y: 0,
                            width: region.size[0],
                            height: region.size[1],
                            filters: region.region.filter
                          }, null, 8, ["onRender", "width", "height", "filters"]))
                        : _createCommentVNode("", true),
                      (region.region.text && _ctx.showRegionText)
                        ? (_openBlock(), _createElementBlock("text", {
                            key: 1,
                            anchor: [region.anchor, 0],
                            style: { fontFamily: 'Arial', fontSize: 34, fill: '#ffffff' },
                            scale: _ctx.textScaleFactor,
                            x: _ctx.calculateRegionTextPosition(region)
                          }, _toDisplayString(region.region.text), 9, _hoisted_1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["x", "y"]))
                }), 128)),
                _renderSlot(_ctx.$slots, "default", {
                  itemProps: { engine: _ctx.engine }
                }, undefined, true),
                (
            _ctx.collisionBorders !== _ctx.CollisionBorderType.None &&
            _ctx.borders &&
            _ctx.showBounds
          )
                  ? (_openBlock(), _createBlock(_component_container, { key: 1 }, {
                      default: _withCtx(() => [
                        (_ctx.borders.bottom)
                          ? (_openBlock(), _createBlock(_component_container, {
                              key: 0,
                              x: _ctx.borders.bottom.position.x,
                              y: _ctx.borders.bottom.position.y
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Graphics, {
                                  onRender: _cache[0] || (_cache[0] = ($event: any) => (_ctx.drawBorder($event, _ctx.borders.bottom))),
                                  x: 0,
                                  y: 0,
                                  width: _ctx.getBodyWidth(_ctx.borders.bottom),
                                  height: _ctx.getBodyHeight(_ctx.borders.bottom)
                                }, null, 8, ["width", "height"])
                              ]),
                              _: 1
                            }, 8, ["x", "y"]))
                          : _createCommentVNode("", true),
                        (_ctx.borders.top)
                          ? (_openBlock(), _createBlock(_component_container, {
                              key: 1,
                              x: _ctx.borders.top.position.x,
                              y: _ctx.borders.top.position.y
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Graphics, {
                                  onRender: _cache[1] || (_cache[1] = ($event: any) => (_ctx.drawBorder($event, _ctx.borders.top))),
                                  x: 0,
                                  y: 0,
                                  width: _ctx.getBodyWidth(_ctx.borders.top),
                                  height: _ctx.getBodyHeight(_ctx.borders.top)
                                }, null, 8, ["width", "height"])
                              ]),
                              _: 1
                            }, 8, ["x", "y"]))
                          : _createCommentVNode("", true),
                        (_ctx.borders.left)
                          ? (_openBlock(), _createBlock(_component_container, {
                              key: 2,
                              x: _ctx.borders.left.position.x,
                              y: _ctx.borders.left.position.y
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Graphics, {
                                  onRender: _cache[2] || (_cache[2] = ($event: any) => (_ctx.drawBorder($event, _ctx.borders.left))),
                                  x: 0,
                                  y: 0,
                                  width: _ctx.getBodyWidth(_ctx.borders.left),
                                  height: _ctx.getBodyHeight(_ctx.borders.left)
                                }, null, 8, ["width", "height"])
                              ]),
                              _: 1
                            }, 8, ["x", "y"]))
                          : _createCommentVNode("", true),
                        (_ctx.borders.right)
                          ? (_openBlock(), _createBlock(_component_container, {
                              key: 3,
                              x: _ctx.borders.right.position.x,
                              y: _ctx.borders.right.position.y
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Graphics, {
                                  onRender: _cache[3] || (_cache[3] = ($event: any) => (_ctx.drawBorder($event, _ctx.borders.right))),
                                  x: 0,
                                  y: 0,
                                  width: _ctx.getBodyWidth(_ctx.borders.right),
                                  height: _ctx.getBodyHeight(_ctx.borders.right)
                                }, null, 8, ["width", "height"])
                              ]),
                              _: 1
                            }, 8, ["x", "y"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 3
            }, 8, ["filters"]),
            (_ctx.showAllEnginColliders)
              ? (_openBlock(), _createBlock(_component_container, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Graphics, {
                      onRender: _cache[4] || (_cache[4] = ($event: any) => (_ctx.drawAllCollider($event))),
                      x: 0,
                      y: 0,
                      width: _ctx.gameWidth,
                      height: _ctx.gameDisplayHeight
                    }, null, 8, ["width", "height"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 3
        }, 8, ["width", "height", "backgroundColor", "backgroundAlpha", "transparent", "onPointerdown", "onPointerup"]))
      : _createCommentVNode("", true),
    (
        _ctx.backgroundMovement === _ctx.BackgroundMovement.Pan &&
        (_ctx.backgroundPositionOffsetMin[0] < _ctx.backgroundPositionOffset[0] ||
          _ctx.endlessPanning)
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_font_awesome_icon, {
            icon: "circle-chevron-right",
            onMousedown: _cache[5] || (_cache[5] = ($event: any) => (_ctx.beginPan([-_ctx.manualPanSpeed, 0]))),
            onTouchstart: _cache[6] || (_cache[6] = ($event: any) => (_ctx.beginPan([-_ctx.manualPanSpeed, 0])))
          })
        ]))
      : _createCommentVNode("", true),
    (
        _ctx.backgroundMovement === _ctx.BackgroundMovement.Pan &&
        (_ctx.backgroundPositionOffsetMax[0] > _ctx.backgroundPositionOffset[0] ||
          _ctx.endlessPanning)
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_font_awesome_icon, {
            icon: "circle-chevron-left",
            onMousedown: _cache[7] || (_cache[7] = ($event: any) => (_ctx.beginPan([_ctx.manualPanSpeed, 0]))),
            onTouchstart: _cache[8] || (_cache[8] = ($event: any) => (_ctx.beginPan([_ctx.manualPanSpeed, 0])))
          })
        ]))
      : _createCommentVNode("", true),
    (
        _ctx.backgroundMovement === _ctx.BackgroundMovement.Pan &&
        (_ctx.backgroundPositionOffsetMax[1] > _ctx.backgroundPositionOffset[1] ||
          _ctx.endlessPanning)
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_font_awesome_icon, {
            icon: "circle-chevron-up",
            onMousedown: _cache[9] || (_cache[9] = ($event: any) => (_ctx.beginPan([0, _ctx.manualPanSpeed]))),
            onTouchstart: _cache[10] || (_cache[10] = ($event: any) => (_ctx.beginPan([0, _ctx.manualPanSpeed])))
          })
        ]))
      : _createCommentVNode("", true),
    (
        _ctx.backgroundMovement === _ctx.BackgroundMovement.Pan &&
        (_ctx.backgroundPositionOffsetMin[1] < _ctx.backgroundPositionOffset[1] ||
          _ctx.endlessPanning)
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_font_awesome_icon, {
            icon: "circle-chevron-down",
            onMousedown: _cache[11] || (_cache[11] = ($event: any) => (_ctx.beginPan([0, -_ctx.manualPanSpeed]))),
            onTouchstart: _cache[12] || (_cache[12] = ($event: any) => (_ctx.beginPan([0, -_ctx.manualPanSpeed])))
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.showFPS)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(Math.round(1000 / _ctx.frameDelta)) + "fps ", 1))
      : _createCommentVNode("", true)
  ], 36)), [
    [_directive_loading, !_ctx.isContainerReady]
  ])
}